// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

/* RAILS */
import "@hotwired/turbo-rails"
import ".././controllers"
import "trix"
import "@rails/actiontext"
import 'core-js/stable';
import 'regenerator-runtime/runtime';

/* VUE */
import './gallery';
// import Vue from 'vue/dist/vue.esm'
// const gallery = new Vue({
//   el: '#gallery',
//   components: { Gallery }
// })
// import Gallery from "@/components/gallery.vue";

/* GSAP */
import { gsap } from 'gsap';
import { ScrollTrigger} from "gsap/ScrollTrigger";
import { CSSPlugin } from "gsap/CSSPlugin";
import { EasePack } from "gsap/EasePack";
gsap.registerPlugin(ScrollTrigger, CSSPlugin, EasePack);

if (window.location.pathname === '/') {
  var tl = gsap.timeline({
    scrollTrigger: {
      trigger: '#best_hero_ever',
      start: "top top",
      end: "bottom top",
      scrub: 1 }}); tl.from('#spiro_logo', { y: "-90%", width: "100%", duration: 1 });
  var tla = gsap.timeline({
    scrollTrigger: {
      trigger: '#f1',
      start: '-100% top',
      end: '30% top',
      scrub: 0.8,
    }}); tla.to('#f1', { y: "100%", duration: 1 });
  var tlb = gsap.timeline({
    scrollTrigger: {
      trigger: '#f2',
      start: '-100% top',
      end: '30% top',
      scrub: 0.8,
    }}); tlb.to('#f2', { y: "100%", duration: 1 });
  var tlc = gsap.timeline({
    scrollTrigger: {
      trigger: '#f3',
      start: '-100% top',
      end: '30% top',
      scrub: 0.8,
    }}); tlc.to('#f3', { y: "100%", duration: 1 });
  var tld = gsap.timeline({
    scrollTrigger: {
      trigger: '#f4',
      start: '-100% top',
      end: '30% top',
      scrub: 0.8,
    }}); tld.to('#f4', { y: "100%", duration: 1 });}

if (window.location.pathname === '/visuals') {
  // console.clear();
  // var { imagesLoaded } = window;
  const allImages = gsap.utils.toArray('.photo-attributes')

  const buttons = {
    prev: document.querySelector(".btn--left"),
    next: document.querySelector(".btn--right"),
  };
  const cardsContainerEl = document.querySelector(".cards__wrapper");
  const galleryBgContainerEl = document.querySelector(".gallery__bg");

  const cardInfosContainerEl = document.querySelector(".info__wrapper");

  buttons.next.addEventListener("click", () => swapCards("right"));

  buttons.prev.addEventListener("click", () => swapCards("left"));

  function swapCards(direction) {
    const currentCardEl = cardsContainerEl.querySelector(".current--card");
    const previousCardEl = cardsContainerEl.querySelector(".previous--card");
    const nextCardEl = cardsContainerEl.querySelector(".next--card");

    const currentBgImageEl = galleryBgContainerEl.querySelector(".current--image");
    const previousBgImageEl = galleryBgContainerEl.querySelector(".previous--image");
    const nextBgImageEl = galleryBgContainerEl.querySelector(".next--image");

    changeInfo(direction);
    swapCardsClass();

    removeCardEvents(currentCardEl);

    function swapCardsClass() {
      currentCardEl.classList.remove("current--card");
      previousCardEl.classList.remove("previous--card");
      nextCardEl.classList.remove("next--card");

      currentBgImageEl.classList.remove("current--image");
      previousBgImageEl.classList.remove("previous--image");
      nextBgImageEl.classList.remove("next--image");

      currentCardEl.style.zIndex = "50";
      currentBgImageEl.style.zIndex = "-2";

      if (direction === "right") {
        previousCardEl.style.zIndex = "20";
        nextCardEl.style.zIndex = "30";

        nextBgImageEl.style.zIndex = "-1";

        currentCardEl.classList.add("previous--card");
        previousCardEl.classList.add("next--card");
        nextCardEl.classList.add("current--card");

        currentBgImageEl.classList.add("previous--image");
        previousBgImageEl.classList.add("next--image");
        nextBgImageEl.classList.add("current--image");
      } else if (direction === "left") {
        previousCardEl.style.zIndex = "30";
        nextCardEl.style.zIndex = "20";

        previousBgImageEl.style.zIndex = "-1";

        currentCardEl.classList.add("next--card");
        previousCardEl.classList.add("current--card");
        nextCardEl.classList.add("previous--card");

        currentBgImageEl.classList.add("next--image");
        previousBgImageEl.classList.add("current--image");
        nextBgImageEl.classList.add("previous--image");
      }
    }
  }

  function changeInfo(direction) {
    let currentInfoEl = cardInfosContainerEl.querySelector(".current--info");
    let previousInfoEl = cardInfosContainerEl.querySelector(".previous--info");
    let nextInfoEl = cardInfosContainerEl.querySelector(".next--info");

    gsap.timeline()
      .to([buttons.prev, buttons.next], {
        duration: 0.2,
        opacity: 0.5,
        pointerEvents: "none",
      })
      .to(
        currentInfoEl.querySelectorAll(".text"),
        {
          duration: 0.4,
          stagger: 0.1,
          translateY: "-120px",
          opacity: 0,
        },
        "-="
      )
      .call(() => {
        swapInfosClass(direction);
      })
      .call(() => initCardEvents())
      .fromTo(
        direction === "right"
          ? nextInfoEl.querySelectorAll(".text")
          : previousInfoEl.querySelectorAll(".text"),
        {
          opacity: 0,
          translateY: "40px",
        },
        {
          duration: 0.4,
          stagger: 0.1,
          translateY: "0px",
          opacity: 1,
        }
      )
      .to([buttons.prev, buttons.next], {
        duration: 0.2,
        opacity: 1,
        pointerEvents: "all",
      });

    function swapInfosClass() {
      currentInfoEl.classList.remove("current--info");
      previousInfoEl.classList.remove("previous--info");
      nextInfoEl.classList.remove("next--info");

      if (direction === "right") {
        currentInfoEl.classList.add("previous--info");
        nextInfoEl.classList.add("current--info");
        previousInfoEl.classList.add("next--info");
      } else if (direction === "left") {
        currentInfoEl.classList.add("next--info");
        nextInfoEl.classList.add("previous--info");
        previousInfoEl.classList.add("current--info");
      }
    }
  }

  function updateCard(e) {
    const card = e.currentTarget;
    const box = card.getBoundingClientRect();
    const centerPosition = {
      x: box.left + box.width / 2,
      y: box.top + box.height / 2,
    };
    let angle = Math.atan2(e.pageX - centerPosition.x, 0) * (35 / Math.PI);
    gsap.set(card, {
      "$current-card-rotation-offset": `${angle}deg`,
    });
    const currentInfoEl = cardInfosContainerEl.querySelector(".current--info");
    gsap.set(currentInfoEl, {
      rotateY: `${angle}deg`,
    });
  }

  function resetCardTransforms(e) {
    const card = e.currentTarget;
    const currentInfoEl = cardInfosContainerEl.querySelector(".current--info");
    gsap.set(card, {
      "$current-card-rotation-offset": 0,
    });
    gsap.set(currentInfoEl, {
      rotateY: 0,
    });
  }

  function initCardEvents() {
    const currentCardEl = cardsContainerEl.querySelector(".current--card");
    currentCardEl.addEventListener("pointermove", updateCard);
    currentCardEl.addEventListener("pointerout", (e) => {
      resetCardTransforms(e);
    });
  }

  initCardEvents();

  function removeCardEvents(card) {
    card.removeEventListener("pointermove", updateCard);
  }

  function init() {

    let tl = gsap.timeline();

    tl.to(cardsContainerEl.children, {
      delay: 0.15,
      duration: 0.5,
      stagger: {
        ease: "power4.inOut",
        from: "right",
        amount: 0.1,
      },
      "$card-translateY-offset": "0%",
    })
      .to(cardInfosContainerEl.querySelector(".current--info").querySelectorAll(".text"), {
        delay: 0.5,
        duration: 0.4,
        stagger: 0.1,
        opacity: 1,
        translateY: 0,
      })
      .to(
        [buttons.prev, buttons.next],
        {
          duration: 0.4,
          opacity: 1,
          pointerEvents: "all",
        },
        "-=0.4"
      );
  }

  const waitForImages = () => {
    const images = [...document.querySelectorAll("img")];
    const totalImages = images.length;
    let loadedImages = 0;
    const loaderEl = document.querySelector(".loader span");

    gsap.set(cardsContainerEl.children, {
      "$card-translateY-offset": "100vh",
    });
    gsap.set(cardInfosContainerEl.querySelector(".current--info").querySelectorAll(".text"), {
      translateY: "40px",
      opacity: 0,
    });
    gsap.set([buttons.prev, buttons.next], {
      pointerEvents: "none",
      opacity: "0",
    });

    images.forEach((image) => {
      imagesLoaded(image, (instance) => {
        if (instance.isComplete) {
          loadedImages++;
          let loadProgress = loadedImages / totalImages;

          gsap.to(loaderEl, {
            duration: 1,
            scaleX: loadProgress,
            backgroundColor: `hsl(${loadProgress * 120}, 100%, 50%`,
          });

          if (totalImages == loadedImages) {
            gsap.timeline()
              .to(".loading__wrapper", {
                duration: 0.8,
                opacity: 0,
                pointerEvents: "none",
              })
              .call(() => init());
          }
        }
      });
    });
  };

  waitForImages();
}

if (window.location.pathname === '/news') {
  gsap.set('.t-effect', { autoAlpha: 0, x: +150 })
  gsap.set('.news-panel', { autoAlpha: 0 })
  gsap.to('.t-effect', {
    scrollTrigger: {
      trigger: '.news-posts-cards-area',
      toggleActions: 'restart pause restart pause'
    },
    x: 0,
    position: 1,
    stagger: 0.6,
    autoAlpha: 1,
    duration: 2
  })
  gsap.to('.news-panel', {
    scrollTrigger: {
      trigger: '.news-posts-cards-area',
      toggleActions: 'restart pause restart pause'
    },
    position: 0.5,
    autoAlpha: 1,
    duration: 1
  })
}

if (window.location.pathname === '/cards') {

  gsap.registerPlugin(ScrollTrigger);
  let iteration = 0;
  const spacing = 0.1,
    snap = gsap.utils.snap(spacing),
    cards = gsap.utils.toArray('.cards li'),
    seamlessLoop = buildSeamlessLoop(cards, spacing),
    scrub = gsap.to(seamlessLoop, {
      totalTime: 0,
      duration: 0.5,
      ease: "power3",
      paused: true
    }),
    trigger = ScrollTrigger.create({
      start: 0,
      onUpdate(self) {
        if (self.progress === 1 && self.direction > 0 && !self.wrapping) {
          wrapForward(self);
        } else if (self.progress < 1e-5 && self.direction < 0 && !self.wrapping) {
          wrapBackward(self);
        } else {
          scrub.vars.totalTime = snap((iteration + self.progress) * seamlessLoop.duration());
          scrub.invalidate().restart();
          self.wrapping = false;
        }
      },
      end: "+=4000",
      pin: ".cards-gallery"
    });

  function wrapForward(trigger) {
    iteration++;
    trigger.wrapping = true;
    trigger.scroll(trigger.start + 1);
  }

  function wrapBackward(trigger) {
    iteration--;
    if (iteration < 0) {
      iteration = 2;
      seamlessLoop.totalTime(seamlessLoop.totalTime() + seamlessLoop.duration() * 10);
      scrub.pause();
    }
    trigger.wrapping = true;
    trigger.scroll(trigger.end - 1);
  }

  function scrubTo(totalTime) {
    let progress = (totalTime - seamlessLoop.duration() * iteration) / seamlessLoop.duration();
    if (progress > 1) {
      wrapForward(trigger);
    } else if (progress < 0) {
      wrapBackward(trigger);
    } else {
      trigger.scroll(trigger.start + progress * (trigger.end - trigger.start));
    }
  }

  document.querySelector(".next").addEventListener("click", () => scrubTo(scrub.vars.totalTime + spacing));
  document.querySelector(".prev").addEventListener("click", () => scrubTo(scrub.vars.totalTime - spacing));

  function buildSeamlessLoop(items, spacing) {
    let overlap = Math.ceil(1 / spacing), // number of EXTRA animations on either side of the start/end to accommodate the seamless looping
      startTime = items.length * spacing + 0.5, // the time on the rawSequence at which we'll start the seamless loop
      loopTime = (items.length + overlap) * spacing + 1, // the spot at the end where we loop back to the startTime
      rawSequence = gsap.timeline({paused: true}),
      seamlessLoop = gsap.timeline({
        paused: true,
        repeat: -1,
        onRepeat() {
          this._time === this._dur && (this._tTime += this._dur - 0.01);
        }
      }),
      l = items.length + overlap * 2,
      time = 0,
      i, index, item;

    gsap.set(items, {xPercent: 400, opacity: 0, scale: 0});

    for (i = 0; i < l; i++) {
      index = i % items.length;
      item = items[index];
      time = i * spacing;
      rawSequence.fromTo(item, {scale: 0, opacity: 0}, {
        scale: 1,
        opacity: 1,
        zIndex: 100,
        duration: 0.5,
        yoyo: true,
        repeat: 1,
        ease: "power1.in",
        immediateRender: false
      }, time)
        .fromTo(item, {xPercent: 400}, {xPercent: -400, duration: 1, ease: "none", immediateRender: false}, time);
      i <= items.length && seamlessLoop.add("label" + i, time);
    }

    rawSequence.time(startTime);
    seamlessLoop.to(rawSequence, {
      time: loopTime,
      duration: loopTime - startTime,
      ease: "none"
    }).fromTo(rawSequence, {time: overlap * spacing + 1}, {
      time: startTime,
      duration: startTime - (overlap * spacing + 1),
      immediateRender: false,
      ease: "none"
    });
    return seamlessLoop;
  }
}
